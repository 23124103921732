*{
    padding: 0;
    margin: 0;
}
@font-face {
    font-family: myfont;
    src: url(../../../../../Fonts/Cristola\ Display.otf);
}
@font-face {
    font-family: bodyfont;
    src: url(../../../../../Fonts/antebas\ medium.otf);
}
.inner-container1{
    width: 99%;
    margin: 0 auto;
    /* border: 1px solid black; */
}
.inner-container1 h1{
    font-family: myfont;
}
.rusticmirror3-col2{
    text-align: center;
}
.rusticmirror3-col3{
    display: flex;
    align-items: end;
    
}
.rusticmirror3-col4{
    text-align: center;
}
.rusticmirror1{
    width: 80%;
}
.rusticmirror2{
    width: 60%;
}
.rusticmirror3{
    width: 80%;
}
.rusticmirror4-img{
    width: 50%;
}
.product-description{
    font-family: bodyfont;
    text-align: left;
}

.description-heads{
    text-decoration: underline;
}
.arrow-container{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
}
.arrowleft{
    width: 50px;
}
.arrowright{
    width: 50px;
}
