/* First Row Container */
@font-face {
    font-family: myfont;
    src: url(../../../Fonts/Cristola\ Display.otf);
}
.turyadesign-Drawers-container{
    padding-top: 50px;
    overflow: hidden;
}
.turyaDrawers-Firstcolumn, .turyaDrawers-Secondcolumn{
    padding: 0;
    margin: 0;
}
.TuryaDrawers-Heading h1{
    text-align: start;
    padding-left: 35px;
    font-family: myfont;
}
.TuryaDrawers-first-one,.TuryaDrawers-first-two{
    text-align: center;
}
.TuryaDrawers-first-two{
    text-align: center;
}
.TuryaDrawers-first-one img{
    width: 90%;
    height: auto;
    margin-top: 120px;
}
.TuryaDrawers-first-two img{
    width: 75%;
    height: auto;
}

.TuryaDrawers-second-one,.TuryaDrawers-second-two,.TuryaDrawers-second-three{
    text-align: center;
    /* border: 1px solid black; */
}
.TuryaDrawers-second-one img{
    width: 60%;
    height: auto;
}
.TuryaDrawers-second-two img{
    width: 48%;
    height: auto;
}
.TuryaDrawers-second-three img{
    width: 60%;
    height: auto;
}


/* Second Row Container */

.TuryaDrawers-Secondrow-First-container{
    text-align: center;
    align-self: flex-end;
}
.TuryaDrawers-Secondrow-Second-container{
    align-self: flex-end;
    text-align: center;
    padding: 0px;
}
.TuryaDrawers-Secondrow-Third-container{
    text-align: center;
}
.TuryaDrawers-Firstone-inner-container{
    text-align: center;
}
.TuryaDrawers-Firstone-inner-container img{
    width: 90%;
    height: auto;
}

.TuryaDrawers-secondone-inner-container img{
    width: 75%;
    height: auto;
}
.TuryaDrawers-Thirdone-inner-container img{
    width: 100%;
    height: auto;
}

/* Next Arrow Container */

.TuryaDrawers-NextArrow-container{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-right: 15px;
    gap: 10px;
}
.TuryaDrawers-Next-Heading{
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    color: black;;
}
.TuryaDrawers-Next-Arrow-img{
    width: 30px;
    height: 40px;
    padding-bottom: 8px;
}




/* Media Query */

@media (max-width: 500px){

    /* First Row Container */

    .TuryaDrawers-first-one img{
        margin-top: 0px;
    }
    .TuryaDrawers-first-two{
        text-align: center;
    }

    /* Second Row Container */
    .TuryaDrawers-second-one img{
        width: 80%;
        height: auto;
    }
    .TuryaDrawers-second-two img{
        width: 80%;
        height: auto;
    }
    .TuryaDrawers-second-three img{
        width: 80%;
        height: auto;
    }

    .TuryaDrawers-Firstone-inner-container{
        text-align: center;
    }
    .TuryaDrawers-secondone-inner-container{
        text-align: center;
    }
    .TuryaDrawers-thirdrow-innerImage-one{
        width: 100%;
        height: auto;
    }
    .TuryaDrawers-NextArrow-container{
        align-items: center;
    }
    .TuryaDrawers-Next-Arrow-img{
        width: 10%;
        height: auto;
        
    }
    
}