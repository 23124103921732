*{
    padding: 0;
    margin: 0;
}
@font-face {
    font-family: myfont;
    src: url(../../../../../Fonts/Cristola\ Display.otf);
}
@font-face {
    font-family: bodyfont;
    src: url(../../../../../Fonts/antebas\ medium.otf);
}
.inner-container1{
    width: 99%;
    margin: 0 auto;
    /* border: 1px solid black; */
}
.inner-container1 h1{
    font-family: myfont;
}
.guncol1{
    /* border: 1px solid black; */
    display: flex;
    align-items: end;
    justify-content: end;
    padding: 0;
}
.guncol2{
    /* border: 1px solid black; */
    text-align: center;
}
.classic1{
    width: 80%;
}
.product-description-signle-container{
    font-family: bodyfont;
  
    /* border: 1px solid red; */
}
.product-description-para-3{
    padding-left: 300px;
    /* margin-top: 70px; */
}
.description-heads{
    text-decoration: underline;
}
.arrow-container{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
}
.arrowleft{
    width: 50px;
}
.arrowright{
    width: 50px;
}
@media screen and (max-width:500px) {
    .guncol1{
        align-items: start;
        justify-content: start;
    }
}