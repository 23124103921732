*{
    padding: 0;
    margin: 0;
}
@font-face {
    font-family: myfont;
    src: url(../../../../../Fonts/Cristola\ Display.otf);
}
@font-face {
    font-family: bodyfont;
    src: url(../../../../../Fonts/antebas\ medium.otf);
}
.mirror-top-head{
    text-decoration: none;
    font-family: myfont;
    color: black;
}
.inner-container1{
    width: 99%;
    margin: 0 auto;
    /* border: 1px solid black; */
}
.inner-container1 h1{
    font-family: myfont;
}
.maplemirror3-col3{
    display: flex;
    align-items: end;
}
.maplemirror1{
    width: 50%;
}
.maplemirror2{
    width: 40%;
}
.maplemirror3{
    width: 50%;
}
.maplemirror4{
    width: 60%;
}
.product-description{
    font-family: bodyfont;
}

.description-heads{
    text-decoration: underline;
}
.arrow-container{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
}
.arrowleft{
    width: 50px;
}
.arrowright{
    width: 50px;
}