/* First Row Tables Container */
@font-face {
    font-family: myfont;
    src: url(../../../Fonts/Cristola\ Display.otf);
}
.turyadesign-Clocks-container {
    padding-top: 50px;
    overflow: hidden;
}

.turyaClocks-Clocks-Firstcolumn,
.turyaClocks-Clocks-Secondcolumn {
    padding: 0;
    margin: 0;
}

.turyaClocks-Clocks-Secondcolumn {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.turyaClocks-Clocks-Heading h1 {
    text-align: start;
    padding-left: 30px;
    font-family: myfont;
}

.turyaClocks-Clocks-first-one,
.turyaClocks-Clocks-first-two{
    text-align: center;
}

.turyaClocks-Clocks-first-one {
    margin-top: 120px;
}

.turyaClocks-Clocks-first-one img {
    width: 80%;
    height: auto;
}
.turyaClocks-Clocks-first-two img {
    width: 100%;
    height: auto;
}

.turyaClocks-Clocks-second-one,
.turyaClocks-Clocks-second-two{
    text-align: center;
}

.turyaClocks-Clocks-second-one img {
    width: 70%;
    height: auto;
}

.turyaClocks-Clocks-second-two img {
    width: 70%;
    height: auto;
}


/* Next Arrow Container */

.turyaClocks-Clocks-NextArrow-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-right: 15px;
    gap: 10px;
}

.turyaClocks-Clocks-Next-Heading {
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    color: black;
}

.turyaClocks-Clocks-Next-Arrow-img {
    width: 30px;
    height: 40px;
    padding-bottom: 8px;
}




/* Media Query */

@media (max-width: 500px) {

    .turyaClocks-Clocks-Firstcolumn,
    .turyaClocks-Clocks-Secondcolumn {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    /* First Row Container */


    .turyaClocks-Clocks-first-one {
        margin-top: 0px;
    }

    .turyaClocks-Clocks-first-two,
    .turyaClocks-Clocks-first-three {
        text-align: center;
    }

    .turyaClocks-Clocks-first-one img {
        width: 90%;
        height: auto;
    }

    .turyaClocks-Clocks-first-two img {
        width: 100%;
        height: auto;
    }

    .turyaClocks-Clocks-first-three img {
        width: 100%;
        height: auto;
    }

    .turyaClocks-Clocks-second-one img {
        width: 70%;
        height: auto;
    }

    .turyaClocks-Clocks-second-two img {
        width: 70%;
        height: auto;
    }

    .turyaClocks-Clocks-second-three img {
        width: 70%;
        height: auto;
    }


    /* NEXT Arrow Container */

    .turyaClocks-Clocks-NextArrow-container {
        align-items: center;
    }

    .turyaClocks-Clocks-Next-Arrow-img {
        width: 10%;
        height: auto;

    }

}